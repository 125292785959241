import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import RemovableBanner from "../../../design-system-components/RemovableBanner/RemovableBanner";
import { DismissableMessages } from "../../../interfaces/Session";

import "./SpecimenBanner.scss";

const SpecimenBanner = () => {
    const { config } = useContext(configStore);
    const {
        session: { dismissMessages },
    } = useContext(sessionStore);
    const link = config.features.specimen?.getFullVersionLink.includes("@")
        ? `mailto:${config.features.specimen?.getFullVersionLink}`
        : config.features.specimen?.getFullVersionLink;
    if (!dismissMessages.specimenGetFullVersionBanner)
        return (
            <RemovableBanner
                messageKey={DismissableMessages.specimenGetFullVersionBanner}
                hasCloseButton={false}
            >
                <FormattedMessage
                    id="specimen-banner-text"
                    defaultMessage="You are using the specimen of {appName}. To access the full version, do not hesitate to contact us {contact_link}."
                    values={{
                        appName: config.client_name,
                        contact_link: (
                            <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                className="specimen-banner__link"
                            >
                                {config.features.specimen?.getFullVersionLink}
                            </a>
                        ),
                    }}
                />
            </RemovableBanner>
        );
    return <></>;
};

export default SpecimenBanner;
