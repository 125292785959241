import React, { useContext } from "react";
import { useUserType } from "../../../hooks/useUserInfo";
import { Pagetype } from "../../../interfaces/Config";
import { Button } from "@evidenceb/athena-common/design-system/Button";
import { defineMessages, useIntl } from "react-intl";
import { UserType } from "../../../interfaces/User";
import { useHistory } from "react-router-dom";
import { sessionStore } from "../../../contexts/SessionContext";
import { useOverlayTriggerState } from "react-stately";
import { useDismissMessage } from "../../MessageDismiss/MessageDismiss";
import SwitchUserModal from "../../Specimen/Modal/Modal";
import { DismissableMessages } from "../../../interfaces/Session";
import { commonMessages } from "../../../utils/messages";
import "./UserTypeSwitch.scss";
import { useSubjects } from "../../../hooks/useActiveSubject";
import { useSubjectChoice } from "../../../pages/SubjectChoice/ChoiceSteps/api/useSubjectChoice";
import { toast } from "react-toastify";
import { Icon } from "@evidenceb/athena-common/design-system/Icon";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
interface Props {
    className?: string;
}

const UserTypeSwitch = ({ className }: Props) => {
    const intl = useIntl();
    const history = useHistory();
    const { setSession } = useContext(sessionStore);
    const requireMapping = useFeatureFlag("requireMapping");
    const userType = useUserType();
    const oppositeUserTypeName =
        userType === UserType.Teacher
            ? intl.formatMessage(commonMessages.student).toLowerCase()
            : intl.formatMessage(commonMessages.teacher);

    const switchUserModal = useOverlayTriggerState({});
    const [__, dismissSwitchPrompt] = useDismissMessage(
        DismissableMessages.specimenSwitchUserModal
    );
    const switchUserHandler = (subjectChosen: string) => {
        setSession((session) => ({
            ...session,
            userType:
                userType === UserType.Student
                    ? UserType.Teacher
                    : UserType.Student,
        }));

        if (userType === UserType.Student && subjectChosen) {
            onSubjectChange(subjectChosen);
        }
        toast(
            intl.formatMessage(messages.toastMessage, {
                userType: oppositeUserTypeName,
            }),
            {
                containerId: "specimen-user-switch",
                icon: <Icon path="checkmark" />,
            }
        );
        history.push("/");
        window.scrollTo({
            top: 0,
        });
    };

    // Subject switch
    const subjects = useSubjects();
    const [_, { mutateAsync }] = useSubjectChoice();
    const onSubjectChange = async (value: string) => {
        const newSubject = subjects.find((subject) => subject.id === value);
        muteExercisesLinks();
        await mutateAsync(newSubject!.id);
    };

    // User switch
    const switchUser = (subjectChosen: string) => {
        switchUserHandler(subjectChosen);
        switchUserModal.close();
        dismissSwitchPrompt();
    };

    const getMessage = () => {
        switch (true) {
            case userType === UserType.Student && requireMapping:
                return intl.formatMessage(messages.switchToTeacherMessage);
            case userType === UserType.Student && !requireMapping:
                return intl.formatMessage(
                    messages.switchToTeacherNoMappingMessage
                );
            case userType === UserType.Teacher:
                return intl.formatMessage(messages.switchToStudentMessage);
        }
    };
    return (
        <>
            <Button
                key={`navItem-${Pagetype.SWITCH_USER_TYPE}`}
                id="switch-btn"
                className={className}
                icons={[
                    {
                        path: "switch-user",
                    },
                ]}
                label={intl.formatMessage(messages.buttonTitle, {
                    userType: oppositeUserTypeName,
                })}
                onClick={switchUserModal.open}
                size="medium"
                variant="custom"
                colors={{
                    background: "var(--element-evidenceb-default)",
                    text: "var(--text-main-inverted)",
                    hoverBackground: "var(--element-button-custom-hover)",
                    hoverText: "var(--text-main-inverted)",
                }}
            />

            {switchUserModal.isOpen && (
                <SwitchUserModal
                    className="switch-user-modal"
                    state={switchUserModal}
                    onModalDismiss={switchUserModal.close}
                    onValidate={switchUser}
                    title={intl.formatMessage(messages.switchTitle, {
                        userType: oppositeUserTypeName,
                    })}
                    content={getMessage()}
                    buttonLabel={intl.formatMessage(messages.buttonTitle, {
                        userType: oppositeUserTypeName,
                    })}
                    userType={userType}
                    displaySubjectChoice={requireMapping}
                />
            )}
        </>
    );
};
export default UserTypeSwitch;

const muteExercisesLinks = () => {
    /*
    This function aims at solving the problem caused by the fact that the switch of
    subject takes some time.
    Without muting links, if a user starts the subject switch and then starts an exercise
    or workshop, once the subject switches completes, ActiveSubject will be incoherent
    with the current exercise, destroying the position data in the player header
    
    => solution implemented here: mute all links contained in resource cards and workshop
    cards, this should prevent users from having a wrong activeSubject
    */
    const links: any = document.querySelectorAll(
        ".ds-resource-card a, .work__resources-list-workshop-card a"
    );
    links.forEach((link: any) => {
        link.parentElement.classList.remove("--clickable");
        link.remove();
    });
};

const messages = defineMessages({
    switchUserType: {
        id: "a11y-switchUserType",
        defaultMessage: "Switch to the {userType} version",
    },
    switchTitle: {
        id: "header-specimen-switchToUser-modalTitle",
        defaultMessage: "Access the {userType} version",
    },
    switchToTeacherMessage: {
        id: "header-specimen-switchToTeacher-modalDescription",
        defaultMessage:
            "Choose the subject you want to see in the teacher view",
    },
    switchToTeacherNoMappingMessage: {
        id: "header-specimen-switchToTeacherNoMappingMessage-modalDescription",
        defaultMessage:
            "Get a preview of the educational resources and dashboards. Unlike the student view, this version displays sample data, and the modules are not personalized by AI. It allows you to explore the available resource catalog.",
    },
    switchToStudentMessage: {
        id: "header-specimen-switchToStudent-modalDescription",
        defaultMessage:
            "On the student interface, you can start a personalized course. The algorithm selects, in a zone of the activity bank that evolves as you go along, the most useful exercises to help the student progress. Each student benefits from a different exercise path.",
    },
    buttonTitle: {
        id: "header-specimen-switchToUser-modalButton",
        defaultMessage: "Access the {userType} version",
    },
    toastMessage: {
        id: "header-specimen-switchToUser-toastMessage",
        defaultMessage: "You are now on the {userType} view of the specimen",
    },
});
