import { AIType } from "@evidenceb/ai-handler";

export interface Student {
    id: string;
    firstname: string;
    lastname: string;
    studentInDifficulty: boolean;
    studentInProgress: boolean;
    testsResults?: TestResult[];
    objectives: Objective[];
    successPercentage: number;
    moduleProgression: number;
    timeSpent: { [Date: string]: number };
}

export interface TestResult {
    type: AIType;
    exercises: TestExercise[];
    status: "none" | "inProgress" | "completed";
    timeSpent: number;
    result: "failed" | "mastered" | "intermediate" | "none";
    moduleCode: string;
}

export interface TestExercise<ExerciseAnswer = any> {
    id: string;
    isCorrect: boolean;
    score: number;
    answer?: ExerciseAnswer;
    involvedActivities?: { objectiveId: string; activityId: string }[];
}

export interface ModuleDashboard {
    id: string;
    participants: number;
    alert: {
        hasDifficulties: number;
        hasImproved: number;
    };
    classSuccessPercentage: number;
    progression: number;
    objectives: string[];
    students: { [studentId: string]: Student };
    index?: number;
    active?: boolean;
}

export interface ClassroomAnalytics {
    id: string;
    external_id: string;
    code: string;
    level: string;
    name: string;
    studentsNumber: number;
    alert: {
        isAlert: boolean;
    };
    modulesList: ModuleDashboard[];
}

export interface ModuleCluster {
    error?: string;
    infosClustering: Clustering;
    infosEleves: { [student: string]: InfosEleves }[];
    infosVariables: { [variable: string]: InfosVariables }[];
    representation: {
        clusters: {
            [cluster: string]: {
                var1: string;
                var2: string;
                var3: string;
            };
        };
        eleves: {
            [student: string]: {
                var1: string;
                var2: string;
                var3: string;
            };
        };
    };
    repartition: { id: string; name: string }[];
}

export interface ClusterError {
    error: string;
    sentry_id: string;
}

export interface ClassroomsClustering {
    [classId: string]: {
        [moduleId: string]: ModuleCluster | ClusterError;
    };
}

export interface Dashboard {
    classrooms: ClassroomAnalytics[];
    clustering?: ClassroomsClustering;
}

export interface ParamTypes {
    classroomId: string;
    moduleId: string;
    groupId: string;
}

export interface StudentAlerts {
    id: string;
    classroomId: string;
    alerts: Alert[];
}
export interface ClassroomStudentsAlerts {
    classroomId: string;
    alerts: {
        [studentId: string]: Alert[];
    };
}
export interface Alert {
    module: string;
    objective: string;
    activity: string;
    exercise: string;
    solved: boolean;
}
export enum AlertType {
    STRENGTH = "strength",
    WEAKNESS = "weakness",
    BLOCKED = "blocked",
}
export interface Clustering {
    exportDate: string;
    computationDate: string;
    erreur: string;
    nbClusters: number;
    clusters: { [clusterName: string]: ClusterInfosClustering }[];
    infosEleves: { [eleveName: string]: EleveInfoClustering }[];
    infosVariables: { [varName: string]: VariablesInfoClustering }[];
    repartition: { id: string; name: string }[];
    representation: {
        clusters: { [clusterName: string]: Representation }[];
        eleves: { [eleveName: string]: Representation }[];
    };
}

export interface InfosClustering {
    [classId: string]: {
        [moduleId: string]: Clustering;
    };
}

export interface ClusterInfosClustering {
    name: string;
    id: string;
    eleves: string[];
    var1: string;
    var2: string;
    var3: string;
    description: string;
}

export interface EleveInfoClustering {
    description: string;
    id: string;
    var1: string;
    var2: string;
    var3: string;
    warning: boolean;
}

export interface VariablesInfoClustering {
    description: string;
    level: string;
    unit: string;
}

export interface Representation {
    var1: string;
    var2: string;
    var3: string;
}

export interface InfosEleves {
    id: string;
    description: string;
    warning: string;
    var1: string;
    var2: string;
    var3: string;
}

export interface InfosVariables {
    description: {
        type: string;
    };
    unit: string;
    level: string;
}

export interface Objective {
    id: string;
    status: "completed" | "inProgress" | "none";
    progression: Progression;
    timeSpent: number;
}

export interface Progression {
    activities: Activity[];
}

export const isDashboardActivity = (obj: any): obj is Activity => {
    return typeof obj === "object" && "name" in obj && "age" in obj;
};

export interface Activity {
    id: string;
    exercises: Exercise[];
}
export interface Exercise {
    id: string;
    unlockedElements: string[];
    success: {
        current: number;
        expected: number;
        alert: "positive" | "negative" | "none";
        isCorrect: boolean;
    };
    timeSpent: number;
    breakPoint: {
        improvement: boolean;
        regression: boolean;
    };
    // TEMP cf EP-1605 ordre d'apparition de l'exercice dans la séquence (ne
    // devrait pas être utilisé dans le front en théorie, le tri devrait être
    // fait dans le back)
    temp_occ: number;
}
