import { Group } from "../../../interfaces/Session";

const queryKeys = {
    getDashboardClassrooms: (teacherId: string, classroomsList: string[]) =>
        ["dashboardClassrooms", { teacherId, classroomsList }] as const,
    getDashboardClassroom: (teacherId: string, classroomId: string) =>
        ["dashboardClassroom", { teacherId, classroomId }] as const,
    getDashboard: (teacherId: string, classroomsList: Group[]) =>
        ["dashboard", { teacherId, classroomsList }] as const,
    getLearningSet: (learningSetId: string, queryParams: { depth?: number }) =>
        ["mapping", learningSetId, queryParams] as const,
    getExternalRedirection: (url: string) =>
        ["externalRedirection", url] as const,
    getClassroomsStudentsAlerts: (
        classroomIds: string[],
        moduleIds: string[]
    ) => ["classroomsStudentsAlerts", classroomIds, moduleIds] as const,
};
export default queryKeys;
