import Session, {
    DuoSession,
    DuoStatus,
    Group as SessionGroup,
} from "../interfaces/Session";
import { DuoSession as UserDuo, RawExtra } from "../interfaces/User";
import {
    Group,
    Student,
    Teacher,
    UserType,
    DuoSession as UserDuoSession,
} from "../interfaces/User";
import { completeLockStatus } from "./pedagogical-ressources";
import { classroomAlphabeticalSort, userAlphabeticalSort } from "./user";

export const userToSession = (
    user: Teacher | Student,
    userType: UserType,
    isSpecimen: boolean | undefined
): Partial<Session> => {
    const common = {
        userId: user.id,
        firstname: user.first_name,
        lastname: user.last_name,
        userProvider: user.provider,
        appProvider: user.provider,
        fragments: user.config.fragments_config ?? [],
        userType: isSpecimen ? UserType.Student : userType,
        extra: formatExtra(user.extra),
    };
    if (userType === UserType.Teacher)
        return {
            ...common,
            classrooms: formatGroups((user as Teacher).classrooms),
            groups: formatGroups((user as Teacher).groups),
        };
    else if (userType === UserType.Student)
        return {
            ...common,
            duos: combineMentorMentee(user as Student),
            resourceAssignments: (
                user as Student
            ).resources_assignments?.filter(
                (assignment) => !!assignment.assigned_resource
            ) as Session["resourceAssignments"],
            classroomIds: user.classrooms as string[],
        };
    return common;
};

export const formatExtra = (extra: RawExtra | undefined) => {
    return !extra || extra === "none" || extra === "null" ? {} : extra;
};

const formatGroups = (groups: Group[]): Session["classrooms"] => {
    return groups
        .map((group) => ({
            id: group.id,
            name: group.name,
            lockStatus: completeLockStatus(group.lockStatus),
            students: group.students
                .map((student) => ({
                    id: student.id,
                    firstname: student.first_name,
                    lastname: student.last_name,
                    extra: formatExtra(student.extra),
                }))
                .sort(userAlphabeticalSort),
            duos: formatDuos(group.students),
            external_id: group.external_id,
        }))
        .sort(classroomAlphabeticalSort);
};

const formatDuos = (students: Group["students"]) => {
    return students.reduce((duos, student) => {
        const newDuos = combineMentorMentee(student).filter((newDuo) =>
            duos.every((existingDuo) => existingDuo.id !== newDuo.id)
        );
        return [...duos, ...newDuos];
    }, [] as DuoSession[]);
};

export const combineMentorMentee = ({
    mentor_sessions = [],
    mentee_sessions = [],
}: {
    mentor_sessions?: UserDuoSession[];
    mentee_sessions?: UserDuoSession[];
}): DuoSession[] => {
    let duos: DuoSession[] = [];
    for (let session of [...mentee_sessions, ...mentor_sessions]) {
        if (duos.every((duo) => duo.id !== session.id))
            duos.push(userDuoToSessionDuo(session));
    }
    return duos;
};

export const userDuoToSessionDuo = (duo: UserDuo): DuoSession => ({
    id: duo.id,
    name: duo.name,
    mentor: {
        firstname: duo.mentor.first_name,
        lastname: duo.mentor.last_name,
        id: duo.mentor.id,
    },
    mentee: {
        firstname: duo.mentee.first_name,
        lastname: duo.mentee.last_name,
        id: duo.mentee.id,
    },
    moduleId: duo.config.module_id,
    status: userDuoStatusToSessionDuoStatus(duo.status),
    teacherId: duo.teacher,
});

const userDuoStatusToSessionDuoStatus = (
    status: UserDuo["status"]
): DuoSession["status"] => {
    switch (status) {
        case "inactive":
            return DuoStatus.Inactive;
        case "active":
            return DuoStatus.Active;
        case "closed":
            return DuoStatus.Completed;
        default:
            /* eslint-disable-next-line */
            const _: never = status;
            return DuoStatus.Inactive; // TODO
    }
};

export const hasStudents = (classrooms: SessionGroup[] | undefined): boolean =>
    classrooms
        ? classrooms.some((classroom) => classroom.students.length > 0)
        : false;
