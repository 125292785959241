import React, { useContext } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { Button } from "@evidenceb/athena-common/design-system/Button";

import "./RemovableBanner.scss";
import { sessionStore } from "../../contexts/SessionContext";
import { DismissableMessages } from "../../interfaces/Session";

interface RemovableBannerProps {
    /**
     * @default "informational"
     */
    variant?: "informational";
    onClose?: () => void;
    messageKey?: DismissableMessages;
    children: React.ReactNode;
    hasCloseButton?: boolean;
    className?: string;
}

const RemovableBanner = ({
    variant = "informational",
    onClose,
    messageKey,
    children,
    hasCloseButton = true,
    className,
}: RemovableBannerProps) => {
    const intl = useIntl();
    const { setSession } = useContext(sessionStore);

    return (
        <div
            className={cn(
                "ds-removable-banner",
                `ds-removable-banner--${variant}`,
                className
            )}
        >
            <p className="ds-removable-banner__content">{children}</p>
            {hasCloseButton && (messageKey || onClose) && (
                <Button
                    variant="primary"
                    icons={[{ placement: "left", path: "close" }]}
                    label={intl.formatMessage({
                        id: "close",
                        defaultMessage: "Close",
                    })}
                    hideLabel
                    onPress={() => {
                        if (messageKey)
                            setSession((session) => ({
                                ...session,
                                dismissMessages: {
                                    ...session.dismissMessages,
                                    [messageKey]: true,
                                },
                            }));
                        if (onClose) onClose();
                    }}
                />
            )}
        </div>
    );
};

export default RemovableBanner;
