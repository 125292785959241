import React, { HTMLAttributes } from "react";
import cn from "classnames";
import {
    AriaTooltipProps,
    TooltipTriggerProps,
    useTooltip,
    useTooltipTrigger,
} from "@react-aria/tooltip";
import { mergeProps } from "@react-aria/utils";
import { TooltipTriggerState, useTooltipTriggerState } from "react-stately";
// SCSS
import "./ToolTip.scss";
import { Overlay, useOverlayPosition } from "@react-aria/overlays";
// Interfaces
export interface TooltipProps {
    className?: string;
    /**
     * The direct child should be a button or link to be able to trigger the
     * tooltip
     */
    children: React.ReactNode;

    state?: TooltipTriggerState;
    /**
     * Ref of the tooltip
     */
    tooltipRef: React.RefObject<HTMLSpanElement>;
    /**
     * Ref of the element related to tooltip
     */
    targetRef: React.RefObject<HTMLElement>;
    /**
     * Position: top or bottom (default is bottom)
     */
    position?: "top" | "bottom";
}
const Tooltip = ({
    children,
    className,
    state,
    tooltipRef,
    targetRef,
    position = "bottom",
    ...props
}: TooltipProps & AriaTooltipProps) => {
    let { tooltipProps } = useTooltip(props, state);

    return (
        <span
            className={cn("ds-tooltip", className)}
            {...mergeProps(props, tooltipProps)}
            ref={tooltipRef}
            style={getTooltipPosition(targetRef, position)}
        >
            {children}
        </span>
    );
};

const getTooltipPosition = (
    targetRef: React.RefObject<HTMLElement>,
    position: "top" | "bottom"
) => {
    if (!targetRef || !targetRef.current) return undefined;

    let top, left;
    const OFFSET_HEIGHT = 7;
    const WIDTH = 150;
    // should use a ref to access the actual height, but tooltipRef is actually the overlayRef
    const tooltipHeight = 40;

    if (position == "top") {
        const targetRect = targetRef.current.getBoundingClientRect();
        top = targetRect.top - tooltipHeight - OFFSET_HEIGHT + window.scrollY;

        left = targetRect.left + window.scrollX;
        if (window.innerWidth - left < WIDTH) {
            left = targetRect.left - WIDTH;
        }

        return { top, left };
    } else {
        const targetRect = targetRef.current.getBoundingClientRect();
        top =
            targetRect.top + targetRect.height + OFFSET_HEIGHT + window.scrollY;
        left = targetRect.left + window.scrollX;
        if (window.innerWidth - left < WIDTH) {
            left = targetRect.left - WIDTH;
        }

        return { top, left };
    }
};

export interface TooltipWrappedProps {
    children: React.ReactNode;
    tooltip: React.ReactNode;
    tooltipClassname?: string;
    wrapper: "button" | "div" | "td";
    wrapperClassname?: string;
    className?: string;
    showOnFocus?: boolean;
    position?: "top" | "bottom";
}

const TooltipWrapper = ({
    children,
    tooltip,
    tooltipClassname,
    wrapperClassname,
    wrapper,
    className,
    showOnFocus,
    position,
    ...props
}: TooltipWrappedProps & TooltipTriggerProps & HTMLAttributes<HTMLElement>) => {
    let state = useTooltipTriggerState(props);
    let ref = React.useRef(null);
    let overlayRef = React.useRef(null);
    const contentWrapper = { wrapper };

    // Get props for the trigger and its tooltip
    let { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref);
    let { overlayProps } = useOverlayPosition({ targetRef: ref, overlayRef });

    return (
        <div className={cn("ds-tooltip__container", className)}>
            <contentWrapper.wrapper
                ref={ref}
                className={wrapperClassname}
                {...triggerProps}
                {...props}
                onFocus={() => {
                    if (showOnFocus) {
                        state.open();
                    }
                }}
            >
                {children}
            </contentWrapper.wrapper>
            {state.isOpen && (
                <Overlay {...overlayProps}>
                    <Tooltip
                        state={state}
                        className={tooltipClassname}
                        tooltipRef={overlayRef}
                        targetRef={ref}
                        position={position}
                        {...tooltipProps}
                    >
                        {tooltip}
                    </Tooltip>
                </Overlay>
            )}
        </div>
    );
};

export { Tooltip, TooltipWrapper };
