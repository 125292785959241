import React, { PropsWithChildren, useEffect, useState } from "react";
import TabTitle from "../TabTitle/TabTitle";
import { MessageDescriptor, useIntl } from "react-intl";
import { VisuallyHidden } from "@evidenceb/athena-common/ui/VisuallyHidden";
import { commonMessages } from "../../utils/messages";
import cn from "classnames";

import "./Loader.scss";

export type Props = PropsWithChildren<
    {
        isLoading: boolean;
        /**
         * @default intl.formatMessage(commonMessages.loading)
         */
        loadingMessage?: string;
        /**
         * @default intl.formatMessage(commonMessages.loadingConcluded)
         */
        loadedMessage?: string;
    } & Omit<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >,
        "aria-busy"
    >
>;

const Loader = ({
    isLoading,
    loadingMessage,
    loadedMessage,
    children,
    ...props
}: Props) => {
    const intl = useIntl();

    // Only announce loading if it takes more than 2 seconds
    const [shouldAnnounceLoading, setShouldAnnounceLoading] =
        useState<boolean>(false);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!shouldAnnounceLoading && isLoading) {
                setShouldAnnounceLoading(true);
            }
        }, MINIMAL_TIME_BEFORE_ANNOUNCING_LOADING);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isLoading, shouldAnnounceLoading]);

    return (
        <>
            {shouldAnnounceLoading && (
                <VisuallyHidden aria-live="polite">
                    {isLoading
                        ? loadingMessage ??
                          intl.formatMessage(commonMessages.loading)
                        : loadedMessage ??
                          intl.formatMessage(commonMessages.loadingConcluded)}
                </VisuallyHidden>
            )}
            {isLoading && <Spinner />}
            <div
                {...props}
                className={cn(props.className, "loader-busy-container")}
                aria-busy={isLoading}
            >
                {children}
            </div>
        </>
    );
};
export default Loader;

const MINIMAL_TIME_BEFORE_ANNOUNCING_LOADING = 2 * 1000;

/**
 * @deprecated Prefer Loader that has accessibility features
 */
export const LegacyLoader = ({
    tabTitle,
}: {
    tabTitle?: string | MessageDescriptor;
}) => {
    return (
        <>
            {tabTitle && <TabTitle title={tabTitle} />}
            <Spinner />
        </>
    );
};

interface SpinnerProps {
    baseClass?: string;
}
const Spinner = (props: SpinnerProps) => {
    const cssClass: string =
        props.baseClass || "evb-spinner__container evb-spinner--animate";
    return (
        <div className={cssClass}>
            <div className="evb-spinner__circle evb-spinner--outer"></div>
            <div className="evb-spinner__dot evb-spinner--outer"></div>
            <div className="evb-spinner__circle evb-spinner--middle"></div>
            <div className="evb-spinner__dot evb-spinner--middle"></div>
            <div className="evb-spinner__circle evb-spinner--inner"></div>
            <div className="evb-spinner__dot evb-spinner--inner"></div>
            <div className="evb-spinner__dot evb-spinner--outer evb-spinner--bottom"></div>
        </div>
    );
};
export { Spinner };
