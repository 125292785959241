import React from "react";
import "./Selection.scss";
import { Subject } from "../../../../interfaces/User";

interface Props {
    currentSubjectChoice: string | undefined;
    onChoiceSelection: (subject: string | undefined) => void;
    choices: Subject[];
    isLoading: boolean;
}

const Selection = ({
    currentSubjectChoice,
    onChoiceSelection,
    choices,
    isLoading,
}: Props) => {
    return (
        <div className="subject-selection">
            <fieldset>
                {choices.map((subject) => {
                    return (
                        <div key={subject.id} className="radio-wrapper">
                            <input
                                type="radio"
                                name="choice"
                                value={subject.name}
                                id={subject.id}
                                onChange={(e) =>
                                    onChoiceSelection(
                                        e.target.attributes.getNamedItem("id")
                                            ?.value
                                    )
                                }
                                disabled={isLoading}
                                checked={currentSubjectChoice === subject.id}
                            />
                            <label
                                htmlFor={subject.id}
                                aria-label={subject.name}
                            >
                                {subject.name}
                            </label>
                        </div>
                    );
                })}
            </fieldset>
        </div>
    );
};

export default Selection;
