export enum Key {
    "LEARNING_SETS_CACHE_STATE" = "LEARNING_SETS_CACHE_STATE",
    "TOKEN" = "TOKEN",
    "VERSION" = "VERSION",
    "LOCAL_HISTORY" = "local-history",
    "CLIENT" = "client",
    /** String list of flags activated using the dev tools */
    "DEV_FLAGS" = "dev-flags",
    /** A AICurriculumFinishedEvent stored when the TAI is finished while exploring results */
    "TEST_FINISHED_EVENT" = "test-finished-event",
}

export const setItem = (key: Key, item: any): void => {
    const oldValue = localStorage.getItem(key);
    const newValue = JSON.stringify(item);
    localStorage.setItem(key, newValue);

    // Send storage event to be picked up by Sentry in particular
    const e = new StorageEvent("storage", {
        storageArea: window.localStorage,
        key,
        oldValue,
        newValue,
        url: window.location.href,
    });
    window.dispatchEvent(e);
};

export function getItem<T>(key: Key): T | undefined {
    const rawItem = localStorage.getItem(key);
    try {
        return rawItem ? (JSON.parse(rawItem) as T) : undefined;
    } catch {
        // @ts-ignore: when T is string
        return rawItem ?? undefined;
    }
}

export const removeItem = (key: Key): void => {
    localStorage.removeItem(key);
};

export const clear = (): void => {
    localStorage.clear();
};
