import {
    AthenaEvent,
    AthenaEventCategory,
    AthenaEventType,
} from "@evidenceb/athena-event-storage-schemas";
import {
    AthenaEventStream,
    Milliseconds,
    ReplicationPullError,
    ReplicationPushNonRetryableError,
    ReplicationPushRetryableError,
    ReplicationPushWrongBatchSizeError,
    SubscriberProcessAthenaEventError,
} from "@evidenceb/athena-events-tools";
import safeStringify from "fast-safe-stringify";
import React, {
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { v4 as uuid } from "uuid";
import { sessionStore } from "./SessionContext";
import useEventStorageClient from "../hooks/useEventStorageClient";
import * as Sentry from "@sentry/react";
import { convertibleAthenaEventTypes } from "../utils/statements/from-athena-event";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";
import {
    PULL_INTERVAL,
    RETRY_FAILED_PUSH_OR_PULL_AFTER,
} from "../const/AthenaEventStream";

export const EventStreamContext = React.createContext<AthenaEventStream | null>(
    null
);

export function useAthenaEventStream() {
    return useContext(EventStreamContext);
}

export function useAthenaEventsRecentlySynced(delta: Milliseconds): boolean {
    const athenaEventStream = useAthenaEventStream();
    const [recentlySynced, setRecentlySynced] = useState(false);
    useEffect(() => {
        athenaEventStream
            ?.waitForRecentSync(delta)
            .then(() => setRecentlySynced(true));
    }, [athenaEventStream, delta]);
    return recentlySynced;
}

/**
 * Function publishing an AthenaEvent to the AthenaEventStream that
 * auto-populates some AthenaEvent properties so that the caller doesn't need to
 * supply them.
 */
export type AthenaEventStreamPublisher = <
    C extends AthenaEventCategory,
    T extends AthenaEventType
>(
    athenaEvent: Omit<
        AthenaEvent<C, T>,
        "id" | "createdAt" | "userId" | "sessionId" | "app"
    >
) => void;

export function useAthenaEventStreamPublisher(): AthenaEventStreamPublisher {
    const athenaEventStream = useAthenaEventStream();
    const { session } = useContext(sessionStore);
    return useMemo(
        () => (athenaEvent) =>
            athenaEventStream &&
            athenaEventStream.publish({
                id: uuid(),
                createdAt: new Date().toISOString(),
                sessionId: session.sessionId,
                userId: session.userId,
                app: session.version,
                ...athenaEvent,
            }),
        [athenaEventStream, session]
    );
}

function useInitAthenaEventStream() {
    const [aes, setAes] = useState<AthenaEventStream | null>(null);
    const { session } = useContext(sessionStore);
    const eventStorageClient = useEventStorageClient();
    const pullAllAthenaEvents = useFeatureFlag("pullAllAthenaEvents");
    useEffect(() => {
        if (!eventStorageClient || !session.userId) {
            return;
        }
        const athenaEventStream = new AthenaEventStream(
            eventStorageClient,
            session.userId,
            {
                onReplicationPullError(error: ReplicationPullError) {
                    Sentry.captureException(error, {
                        extra: { cause: safeStringify(error.cause) },
                    });
                },
                onSubscriberProcessAthenaEventError(
                    error: SubscriberProcessAthenaEventError
                ) {
                    Sentry.captureException(error, {
                        extra: {
                            athenaEvent: safeStringify(error.athenaEvent),
                            subscriberId: error.subscriberId,
                            error: safeStringify(error.error),
                        },
                    });
                },
                onReplicationPushWrongBatchSizeError(
                    error: ReplicationPushWrongBatchSizeError
                ) {
                    Sentry.captureException(error, {
                        extra: { batchSize: error.batchSize },
                    });
                },
                onReplicationPushRetryableError(
                    error: ReplicationPushRetryableError
                ) {
                    Sentry.captureException(error, {
                        extra: {
                            athenaEventSaveResult: safeStringify(
                                error.athenaEventSaveResult
                            ),
                        },
                    });
                },
                onReplicationPushNonRetryableError(
                    error: ReplicationPushNonRetryableError
                ) {
                    Sentry.captureException(error, {
                        extra: {
                            athenaEventSaveResult: safeStringify(
                                error.athenaEventSaveResult
                            ),
                        },
                    });
                },
            },
            !pullAllAthenaEvents
                ? [...convertibleAthenaEventTypes, "AIShowMessageEvent"]
                : undefined,
            PULL_INTERVAL,
            RETRY_FAILED_PUSH_OR_PULL_AFTER
        );
        const start = async () => {
            await athenaEventStream.start();
            setAes(athenaEventStream);
        };
        start();
        return () => {
            athenaEventStream.stop();
        };
    }, [eventStorageClient, session.userId, pullAllAthenaEvents]);
    return aes;
}

export function EventStreamProvider({ children }: { children: ReactNode }) {
    const athenaEventStream = useInitAthenaEventStream();
    return (
        <EventStreamContext.Provider value={athenaEventStream}>
            {children}
        </EventStreamContext.Provider>
    );
}
