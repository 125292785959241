import { useContext, useEffect } from "react";
import { sessionStore } from "../../contexts/SessionContext";
import * as Sentry from "@sentry/react";
import Session from "../../interfaces/Session";
import * as localStorage from "../../utils/localStorage";
import { LoaderStatus } from "../../interfaces/Status";

const useSetSentryContext = () => {
    const { session } = useContext(sessionStore);
    useEffect(() => {
        const sessionInfo = prepareSessionForSentryContext(session);
        const handleStorageUpdate = () => {
            const localStorageFlags = getStorageFlags();
            const sentrySessionContext = {
                ...Object.fromEntries(sessionInfo),
                localStorageFlags,
            };
            Sentry.setContext("Session", sentrySessionContext);
        };
        window.addEventListener("storage", handleStorageUpdate);
        handleStorageUpdate();
        return () => {
            window.removeEventListener("storage", handleStorageUpdate);
        };
    }, [session]);
};
export default useSetSentryContext;

type SessionKeys = keyof Required<Session>;
/**
 * Describes how each key of the session context is parsed to be added to the
 * Sentry context. False means it's not added, true that it's added without
 * change, and the function is used to parse the value
 */
const sessionLoggerPrepare: {
    [key in SessionKeys]:
        | boolean
        | ((item: Session[key]) => string | undefined);
} = {
    sessionId: true,
    userId: true,
    firstname: true,
    lastname: true,
    userProvider: true,
    appProvider: false, // same as "useProvider"
    appVariation: false, // less complete than "variation"
    userType: true,
    evidencebId: false, // same as userId
    version: true,
    extra: JSON.stringify,
    theme: true,
    flags: false, // not relevant
    dismissMessages: false, // not relevant
    fragments: true,
    specimen: true,
    app: true,
    soloAI: false, // too large
    statementsFetched: true,
    statements: false, // too large
    athenaEvents: false, // too large
    prLockStatus: (prLockStatus) => {
        if (prLockStatus?.status !== LoaderStatus.Success)
            return prLockStatus?.status;
        return JSON.stringify(prLockStatus.payload);
    },
    duos: JSON.stringify,
    resourceAssignments: JSON.stringify,
    school: JSON.stringify,
    classrooms: JSON.stringify,
    groups: JSON.stringify,
    classroomIds: JSON.stringify,
    experimentationTests: JSON.stringify,
};

const prepareSessionForSentryContext = (session: Session) => {
    const preparedSession: Map<string, any> = new Map();
    for (const key in session) {
        const item = prepareItem(
            key as keyof Session,
            session[key as keyof Session]
        );
        if (item) preparedSession.set(key, item[key]);
    }
    return preparedSession;
};

const prepareItem = <T extends keyof Session>(key: T, value: Session[T]) => {
    const prepareItemFn = sessionLoggerPrepare[key];
    if (prepareItemFn === false) return undefined;
    if (prepareItemFn === true) return { [key]: value };
    return { [key]: prepareItemFn(value) };
};

const getStorageFlags = (): string[] => {
    return localStorage.getItem<string[]>(localStorage.Key.DEV_FLAGS) ?? [];
};
