import { useContext, useMemo, useState } from "react";
import { Statement } from "@xapi/xapi";
import {
    Classroom,
    TokenPayload,
    Student,
    User,
    UserType,
    DuoSession,
    Teacher,
    UserExtra,
} from "../../../interfaces/User";
import * as Sentry from "@sentry/react";
import { axiosClient } from "../../../utils/axios-client/axios-client";
import * as localStorage from "../../../utils/localStorage";
import { configStore } from "../../../contexts/ConfigContext";
import { errorStore } from "../../../contexts/ErrorContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { completeLockStatus } from "../../../utils/pedagogical-ressources";
import { PRLockStatus } from "../../../interfaces/PedagogicalResourcesManagement";
import {
    ClassroomData,
    ClassroomPayload,
    StudentPayload,
    TeacherPayload,
    UserData,
} from "../../../interfaces/Authentication";
import axios, { AxiosError } from "axios";
import { Account } from "../../../interfaces/AuthAPI";
import { handleActionTokenError } from "../../../utils/api-client-utils";
import {
    ClassroomStudentsAlerts,
    Dashboard,
    StudentAlerts,
} from "../../../interfaces/Dashboard";
import { isSpecimenVersion } from "../../../utils/init";
import { isNewResourcesSystem } from "./utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import queryKeys from "./queryKeys";
import { Data } from "../../../interfaces/Data";
import { GlobalConfig } from "../../useInitApp/useInitApp";
import { removeNonExistantModules } from "../../../utils/dataRetrieval";
import { LearningSet } from "../../../interfaces/AthenaResources";
import { MappingNode } from "../../../interfaces/MappingNode";
import { dataStore } from "../../../contexts/DataContext";
import { useFeatureFlag } from "@evidenceb/athena-common/modules/FeatureFlags";

const useAthenaAPIClient = () => {
    // const error5xx = /(40[0-9]|4[1-9][0-9])/g;

    const {
        config: { apiUrls },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const { setErrorInfo } = useContext(errorStore);
    const { session } = useContext(sessionStore);

    // when specimen variation is set, some requests
    // are changed to a specific url where variation needs to be used
    // It creates probably technical debt so it needs also to be refactor
    const [specimenVariation, setSpecimenVariation] = useState<false | string>(
        isSpecimenVersion() ? session.version : "false"
    );

    const disableXapi = useFeatureFlag("disableXapiEvents");

    // const captureError5xx = (error: any) => {
    //     let status = error.response.status.toString();
    //     if (status.match(error5xx)) {
    //         Sentry.captureException(error);
    //     }
    // };

    const athenaAPIClient = useMemo(() => {
        const specimen = {
            getTokenPayload: (): Promise<TokenPayload> => {
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/user-info/?variation=${specimenVariation}`
                    )
                    .then((response) => {
                        return response.data as TokenPayload;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        Sentry.captureException({
                            error: error,
                            messsage: "ERROR: Specimen getTokenPayload error",
                        });
                        console.log(error);
                        throw error;
                    });
            },
            async getUser() {
                const { data } = await axiosClient.axios.get<Teacher | Student>(
                    `${apiUrls.endpoints.users}/user?variation=${specimenVariation}`
                );
                return data;
            },
            async getResourcesLockStatus(
                userId: string,
                userType: UserType
            ): Promise<{ [classroomId: string]: PRLockStatus }> {
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/lock_status/${userType.toLowerCase()}/${userId}/?variation=${specimenVariation}`
                );
                const allLockStatus = res.data as {
                    [classroomId: string]: Partial<PRLockStatus>;
                };
                return Object.keys(allLockStatus).reduce(
                    (lockStatus, classroomId) => {
                        return {
                            ...lockStatus,
                            [classroomId]: completeLockStatus(
                                allLockStatus[classroomId]
                            ),
                        };
                    },
                    {} as { [classroomId: string]: PRLockStatus }
                );
            },
            getStatements(): Promise<Statement[]> {
                return new Promise((res) => {
                    res([]);
                });
            },
            getClassroom(classroomId: string): Promise<Classroom> {
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/?variation=${specimenVariation}`
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    });
            },
            getTeacherDashboard(teacherId: string): Promise<Dashboard> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                    version: session.version,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.analytics}/teacher/${teacherId}?version=${specimenVariation}`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Dashboard>;
                    });
            },
            async updateUserExtra(
                _extra: UserExtra,
                _userId?: string,
                _userType?: UserType
            ): Promise<User> {
                return this.getUser();
            },
        };

        return {
            setSpecimenVariation,
            specimenVariation,
            getTokenPayload: async (): Promise<TokenPayload> => {
                if (isSpecimenVersion())
                    return await specimen.getTokenPayload();
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.users}/user-info/`, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as TokenPayload;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        Sentry.captureException({
                            error: error,
                            messsage: "ERROR: getTokenPayload error",
                        });
                        console.log(error);
                        throw error;
                    });
            },

            async updateUserExtra(
                extra: UserExtra,
                userId?: string,
                userType?: UserType
            ): Promise<User> {
                if (isSpecimenVersion())
                    return specimen.updateUserExtra(extra, userId, userType);
                const _userType = userType ?? session.userType;
                const _userId = userId ?? session.userId;
                const res = await axiosClient.axios.patch(
                    `${
                        apiUrls.endpoints.users
                    }/api/${_userType.toLocaleLowerCase()}s/${_userId}/`,
                    {
                        extra,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem<string>(
                                localStorage.Key.TOKEN
                            )}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                return res.data;
            },

            async getUser(
                tokenPayload: Pick<TokenPayload, "sub" | "role">
            ): Promise<Teacher | Student> {
                if (isSpecimenVersion()) return await specimen.getUser();
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/${tokenPayload.role}s/${tokenPayload.sub}/`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Teacher | Student>;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        Sentry.captureException({
                            error: error,
                            messsage: "ERROR: getUser error",
                        });
                        console.log(error);
                        throw error;
                    });
            },

            getStudent<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.users}/api/students/${userId}/`, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<T>;
                    });
            },

            getTeacher<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.users}/api/teachers/${userId}/`, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<T>;
                    });
            },

            createStudents(data: Student[]): Promise<Student[]> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(`${apiUrls.endpoints.users}/api/students/`, data, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<Student[]>;
                    });
            },

            async updateUser(
                type: string,
                userId: string,
                data: object
            ): Promise<User> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                const response = await axiosClient.axios.patch<User>(
                    `${apiUrls.endpoints.users}/api/${type}s/${userId}/`,
                    data,
                    {
                        headers: headers,
                    }
                );

                return response.data;
            },

            async getClassroom(classroomId: string): Promise<Classroom> {
                if (isSpecimenVersion())
                    return await specimen.getClassroom(classroomId);
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    });
            },

            getClassroomsByCode(classroomCode: string): Promise<Classroom[]> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/classrooms/?code=${classroomCode}`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom[]>;
                    });
            },

            getClassroomsByExternalId<T>(
                variation: string,
                externalId: string
            ): Promise<T> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                let params = `?variation=${variation}&external_id=${externalId}`;
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/classrooms/${params}`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    });
            },

            updateClassroom(
                classroomId: string | undefined,
                data: object
            ): Promise<Classroom> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .patch(
                        `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/`,
                        data,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    });
            },

            createClassroom(data: {
                name: string | undefined;
            }): Promise<Classroom> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(`${apiUrls.endpoints.users}/api/classrooms/`, data, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    });
            },

            deleteClassroom<T>(id: string): Promise<T> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .delete(`${apiUrls.endpoints.users}/api/classrooms/${id}`, {
                        headers: headers,
                    })
                    .then((response) => {
                        console.log("Classroom deleted, reload the page");
                        return response.data as Promise<T>;
                    });
            },

            async getGlobalConfig(
                version: string,
                fragments?: string[]
            ): Promise<GlobalConfig> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                };
                let endpoint = `${apiUrls.endpoints.content}`;
                let params = new URLSearchParams({
                    target: "config",
                    version,
                });
                if (isNewResourcesSystem(version)) {
                    endpoint = apiUrls.endpoints.resources!;
                    if (fragments) {
                        for (const frag of fragments)
                            params.append("fragments", frag);
                    }
                }
                const response = await axiosClient.axios.get<GlobalConfig>(
                    `${endpoint}/content?${params.toString()}`,
                    { headers }
                );
                return response.data;
            },

            async getData(
                version: string,
                initWithAdaptiveTest: boolean | undefined,
                /** @default "questions" */
                target?: "questions" | "exercises-only" | "questions-no-content"
            ): Promise<Data> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                };
                let endpoint = `${apiUrls.endpoints.content}`;
                const params = { target: "questions", version };
                if (isNewResourcesSystem(version) || initWithAdaptiveTest) {
                    endpoint = apiUrls.endpoints.resources!;
                    if (target) {
                        params.target = target;
                    }
                }

                let queryParams = new URLSearchParams(params).toString();

                const response = await axiosClient.axios.get<Data>(
                    `${endpoint}/content?${queryParams}`,
                    { headers }
                );
                return response.data;
            },

            async getLearningSet(
                learningSetId: string,
                queryParamsObject: Record<string, string>
            ): Promise<
                { [key: string]: LearningSet } | LearningSet | undefined
            > {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                };

                if (isSpecimenVersion() && specimenVariation)
                    queryParamsObject.variation = specimenVariation;
                const queryParams = new URLSearchParams(
                    queryParamsObject
                ).toString();
                const response = await axiosClient.axios.get<{
                    [key: string]: LearningSet;
                }>(
                    `${apiUrls.endpoints.resources}/learning_sets/${learningSetId}?${queryParams}`,
                    { headers }
                );
                return response.data;
            },

            async getMappingTree() {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                };
                let url = `${apiUrls.endpoints.resources}/mapping/tree`;
                if (isSpecimenVersion())
                    url += `?variation=${specimenVariation}`;
                const response = await axiosClient.axios.get<MappingNode>(url, {
                    headers,
                });
                return response.data;
            },

            async getTeacherDashboardClassroomInf({
                pageParam,
                queryKey,
            }: QueryFunctionContext<
                ReturnType<(typeof queryKeys)["getDashboardClassrooms"]>,
                string
            >): Promise<Dashboard> {
                const { teacherId, classroomsList } = queryKey[1];
                if (isSpecimenVersion())
                    return await specimen.getTeacherDashboard(teacherId);
                const classroomIdx = pageParam ? Number(pageParam) : 0;
                const classroomId = classroomsList[classroomIdx];
                // console.log("Fetch single classroom:", classroomId);
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                    version: session.version,
                };
                const res = await axiosClient.axios.get<Dashboard>(
                    `${apiUrls.endpoints.analytics}/teacher/${teacherId}/?classroom_id=${classroomId}`,
                    {
                        headers: headers,
                    }
                );
                // console.log(`single classroom fetched, ${classroomId}.`);
                return res.data;
            },

            async getTeacherDashboardClassroom(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getDashboardClassroom"]>
                >
            ): Promise<Dashboard> {
                const { teacherId, classroomId } = context.queryKey[1];
                if (isSpecimenVersion())
                    return await specimen.getTeacherDashboard(teacherId);

                console.log("Fetch single classroom:", classroomId);
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                    version: session.version,
                };
                const res = await axiosClient.axios.get<Dashboard>(
                    `${apiUrls.endpoints.analytics}/teacher/${teacherId}/?classroom_id=${classroomId}`,
                    {
                        headers: headers,
                    }
                );
                console.log(`single classroom fetched, ${classroomId}.`);
                return res.data;
            },

            async getTeacherDashboard(
                context: QueryFunctionContext<
                    ReturnType<(typeof queryKeys)["getDashboard"]>
                >
            ): Promise<Dashboard> {
                const { teacherId, classroomsList } = context.queryKey[1];
                if (isSpecimenVersion())
                    return await specimen.getTeacherDashboard(teacherId);
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                    version: session.version,
                };

                console.log("Fetching dashboard per classroomS...");

                let results: Dashboard = {
                    classrooms: [],
                    clustering: {},
                };

                let idx = 0;
                for (const classroom of classroomsList.filter(
                    (c) => c.students.length > 0
                )) {
                    console.log(
                        `${idx}/${classroomsList.length} - ${classroom.id}`
                    );
                    idx++;
                    const res = await axiosClient.axios.get<Dashboard>(
                        `${apiUrls.endpoints.analytics}/teacher/${teacherId}/?classroom_id=${classroom.id}`,
                        {
                            headers: headers,
                        }
                    );
                    results.classrooms.push(...res.data.classrooms);
                    results.clustering = {
                        ...results.clustering,
                        ...res.data.clustering,
                    };
                }
                console.log(
                    `dashboard fetched, ${results.classrooms.length} classrooms.`
                );
                return removeNonExistantModules(results, data);
            },

            async getClassroomsStudentsAlerts(
                context: QueryFunctionContext<
                    ReturnType<
                        (typeof queryKeys)["getClassroomsStudentsAlerts"]
                    >
                >
            ): Promise<StudentAlerts[]> {
                const [, classromIds, moduleIds] = context.queryKey;

                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evb-origin": window.location.origin,
                    version: session.version,
                };
                const alerts: ClassroomStudentsAlerts[] = [];
                await Promise.all(
                    classromIds?.length
                        ? classromIds.map(async (classroomId) => {
                              const queryString = moduleIds
                                  .map(
                                      (id) =>
                                          `module_ids=${encodeURIComponent(id)}`
                                  )
                                  .join("&");
                              const res = await axiosClient.axios.get<
                                  Omit<ClassroomStudentsAlerts, "classroomId">
                              >(
                                  `${apiUrls.endpoints.analytics}/alerts/?classroom_id=${classroomId}&${queryString}`,
                                  {
                                      headers: headers,
                                  }
                              );
                              alerts.push({
                                  classroomId: classroomId,
                                  alerts: res.data.alerts,
                              });
                          })
                        : []
                );
                const studentsAlert: StudentAlerts[] = [];
                alerts.forEach((classroom) => {
                    Object.entries(classroom.alerts).forEach(([key, value]) => {
                        studentsAlert.push({
                            id: key,
                            classroomId: classroom.classroomId,
                            alerts: value,
                        });
                    });
                    return studentsAlert;
                });
                return studentsAlert;
            },

            postStatement<T>(statement: Statement): Promise<T> {
                if (disableXapi)
                    return new Promise((res) => {
                        res(undefined as T); // This might be not be very idiomatic.
                    });

                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evidenceb-app": "athena",
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.statements!, statement, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data as Promise<T>;
                    });
            },

            getStatements(agent: object): Promise<Statement[]> {
                if (isSpecimenVersion()) return specimen.getStatements();
                if (disableXapi)
                    return new Promise((res) => {
                        res([]);
                    });
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "x-evidenceb-app": "athena",
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints
                            .statements!}?agent=${encodeURIComponent(
                            JSON.stringify(agent)
                        )}`,
                        {
                            headers: headers,
                        }
                    )
                    .then((res) => {
                        return res.data as Promise<{
                            more: any;
                            statements: Statement[];
                        }>;
                    })
                    .then((res) => res.statements);
            },

            logout<T>(): Promise<T> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                // URL set in .env for now
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.auth}/session/logout`, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data.SessionKilled as Promise<T>;
                    })
                    .catch((error) => {
                        console.log(error);
                        return error;
                    });
            },

            async getResourcesLockStatus(
                userId: string,
                userType: UserType
            ): Promise<{ [classroomId: string]: PRLockStatus }> {
                if (isSpecimenVersion())
                    return await specimen.getResourcesLockStatus(
                        userId,
                        userType
                    );
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/lock_status/${userType.toLowerCase()}/${userId}/`,
                    {
                        headers: headers,
                    }
                );
                const allLockStatus = res.data as {
                    [classroomId: string]: Partial<PRLockStatus>;
                };
                return Object.keys(allLockStatus).reduce(
                    (lockStatus, classroomId) => {
                        return {
                            ...lockStatus,
                            [classroomId]: completeLockStatus(
                                allLockStatus[classroomId]
                            ),
                        };
                    },
                    {} as { [classroomId: string]: PRLockStatus }
                );
            },

            async updateResourcesLockStatus(
                classroomId: string,
                lockStatus: PRLockStatus
            ): Promise<void> {
                let headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                };
                await axiosClient.axios.patch(
                    `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/`,
                    { lock_status: lockStatus },
                    {
                        headers: headers,
                    }
                );
            },

            async redirectToExternalLink(
                context: QueryFunctionContext<
                    ReturnType<typeof queryKeys.getExternalRedirection>
                >
            ) {
                const response = await axiosClient.axios.post(
                    `${apiUrls.endpoints.auth}/cas/gar/external-redirect`,
                    {
                        target_url: context.queryKey[1],
                        token: localStorage.getItem<string>(
                            localStorage.Key.TOKEN
                        ),
                    }
                );
                return response.data;
            },

            async createDuo(
                mentee: string,
                mentor: string,
                moduleId: string
            ): Promise<DuoSession> {
                const headers = {
                    Authorization: `Bearer ${localStorage.getItem<string>(
                        localStorage.Key.TOKEN
                    )}`,
                    "Content-Type": "application/json",
                };
                const response = await axiosClient.axios.post<DuoSession>(
                    `${apiUrls.endpoints.users}/api/multiplayer-sessions/?notify=true`,
                    {
                        teacher: session.userId,
                        mentee,
                        mentor,
                        config: {
                            type: "module_duo",
                            module_id: moduleId,
                        },
                    },
                    { headers }
                );
                return response.data;
            },
        };
    }, [
        apiUrls,
        setErrorInfo,
        specimenVariation,
        setSpecimenVariation,
        session.userId,
        session.version,
        data,
        session.userType,
        disableXapi,
        //session.classrooms,
    ]);

    return athenaAPIClient;
};

/**
 * This is the client used in the authentication process. Since we do not want
 * to verify that the user has a valid token, we use the generic axios client
 * and not our custom one.
 */
export const useAthenaTokenlessAPIClient = () => {
    const {
        config: { apiUrls },
    } = useContext(configStore);

    const { session } = useContext(sessionStore);

    const tokenlessClient = useMemo(
        () => ({
            async directAuthStudent(body: StudentPayload) {
                try {
                    const res = await axios.post<UserData[]>(
                        `${apiUrls.endpoints.auth}/direct-auth/student`,
                        body
                    );
                    // TEMP: The list represents the variations the user has
                    // access to. For now we choose the first one because a user
                    // shouldn't have access to multiple variations
                    return res.data[0];
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async directAuthTeacher(body: TeacherPayload) {
                try {
                    const res = await axios.post<
                        (TokenPayload & { token: string })[]
                    >(`${apiUrls.endpoints.auth}/direct-auth/teacher`, body);

                    const payload = res.data.find(
                        (payload) =>
                            `${payload.app}/${payload.version}` === session.app
                    );

                    return payload;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async classroomCodeValidation(body: ClassroomPayload) {
                try {
                    const res = await axios.post<ClassroomData>(
                        `${apiUrls.endpoints.auth}/direct-auth/classroom`,
                        body
                    );
                    return res.data;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async verifyActionToken(actionToken: string) {
                try {
                    const res = await axios.post<Account>(
                        `${apiUrls.endpoints.auth}/direct-auth/verify-action-token?token=${actionToken}`
                    );
                    return res.data;
                } catch (err) {
                    handleActionTokenError(err as AxiosError);
                    throw err;
                }
            },

            async updatePassword(
                newPwd: string,
                actionToken: string,
                app: string,
                email: string
            ) {
                try {
                    const res = await axios.post<
                        | (TokenPayload & { token: string })[]
                        | { detail: "same_password" | "mismatch" }
                    >(`${apiUrls.endpoints.auth}/direct-auth/update-password`, {
                        action_token: actionToken,
                        password: newPwd,
                        app,
                        role: "teacher",
                        email,
                    });
                    const payload = Array.isArray(res.data)
                        ? res.data.find(
                              (payload) =>
                                  `${payload.app}/${payload.version}` ===
                                  session.app
                          )
                        : res.data;
                    if (payload === undefined) {
                        throw new Error(
                            `No Teacher found for variation ${app}`
                        );
                    }
                    return payload;
                } catch (err) {
                    handleActionTokenError(err as AxiosError);
                    throw err;
                }
            },

            async requestNewPassword(email: string, variation: string) {
                try {
                    await axios.post(
                        `${apiUrls.endpoints.auth}/email-registration/request-new-password`,
                        {
                            email,
                            variation,
                            status: "forgot_password",
                        }
                    );
                } catch (err) {
                    Sentry.captureException(err);
                    throw err;
                }
            },
        }),
        [apiUrls, session.app]
    );

    return tokenlessClient;
};

export default useAthenaAPIClient;
