import React, { useState } from "react";
import { OverlayTriggerState } from "react-stately";

import { Modal } from "@evidenceb/athena-common/design-system/Modal";

import "./Modal.scss";
import Selection from "../../../pages/SubjectChoice/ChoiceSteps/Selection/Selection";
import { useSubjects } from "../../../hooks/useActiveSubject";
import { UserType } from "../../../interfaces/User";

interface ResetProgressionModalProps {
    state: OverlayTriggerState;
    onModalDismiss: () => void;
    onValidate: (subjectChosen: string) => void;
    title: string;
    content: string | undefined;
    buttonLabel: string;
    userType: UserType;
    displaySubjectChoice: boolean;
    className: string;
}

const ResetProgressionModal = ({
    state,
    onModalDismiss,
    onValidate,
    title,
    content,
    buttonLabel,
    userType,
    displaySubjectChoice,
    className,
}: ResetProgressionModalProps) => {
    const [currentSubjectChoice, setCurrentSubjectChoice] = useState<string>();
    const subjects = useSubjects();
    const onChoiceSelection = (subject: string | undefined) => {
        if (!subject) return;
        setCurrentSubjectChoice(subject);
    };
    return (
        <Modal
            className={className}
            open={state.isOpen}
            title={title}
            onClose={onModalDismiss}
            footerButtons={[
                {
                    label: buttonLabel,
                    onClick: () => onValidate(currentSubjectChoice!),
                    disabled:
                        userType === UserType.Student &&
                        !currentSubjectChoice &&
                        displaySubjectChoice,
                },
            ]}
        >
            {content && <p>{content}</p>}
            {displaySubjectChoice && userType === UserType.Student && (
                <Selection
                    currentSubjectChoice={currentSubjectChoice}
                    onChoiceSelection={onChoiceSelection}
                    choices={subjects}
                    isLoading={false}
                />
            )}
        </Modal>
    );
};

export default ResetProgressionModal;
