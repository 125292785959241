import { WorkMode, WorkModeStatus } from "../interfaces/Config";
import { UserType } from "../interfaces/User";
import { useWorkmodeConfig } from "./useConfig";
import { useUserType } from "./useUserInfo";
import { WorkModeType } from "@evidenceb/athena-common/interfaces/Workmode";
export const useHasWorkmode = (
    workmodeType: WorkModeType,
    /**
     * @default current user type
     */
    userType?: UserType
) => {
    const currentUserType = useUserType();
    const workmodeConfig = useWorkmodeConfig(workmodeType);
    return hasWorkmode(userType ?? currentUserType, workmodeConfig);
};

export const hasWorkmode = (
    userType: UserType,
    workmodeConfig: WorkMode | undefined
) => {
    if (!workmodeConfig) {
        // For legacy version where workmodeConfig doesn't exist
        return true;
    } else if (!workmodeConfig.status) {
        // Default status is active
        return true;
    } else if (Array.isArray(workmodeConfig.status)) {
        return workmodeConfig.status.some(
            (authorization) =>
                authorization.userType === userType &&
                (authorization.status === WorkModeStatus.Active ||
                    authorization.status === WorkModeStatus.NotClickable)
        );
    } else {
        return (
            workmodeConfig.status === WorkModeStatus.Active ||
            workmodeConfig.status === WorkModeStatus.NotClickable
        );
    }
};
